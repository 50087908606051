<template>
  <div>
    <Badge variant="danger" label="Alpha" v-if="formattedStage === 'alpha'" />
    <Badge variant="secondary" label="Beta" v-if="formattedStage === 'beta'" />
    <Badge variant="success" label="Release" v-if="formattedStage === 'release'" />
  </div>
</template>
<script>
import Badge from '@/components/rbComponents/Badge';
export default {
  components: {
    Badge
  },
  props: {
    stage: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedStage() {
      return this.stage.toLowerCase();
    }
  }
};
</script>
