<template>
  <div>
    <div class="flex justify-center" v-if="loading && !errorMessage">
      <LoadingSpinner />
    </div>
    <Alert variant="red" icon="exclamation" v-if="!loading && errorMessage">{{ errorMessage }}</Alert>
    <div class="flex flex-wrap gap-8" v-else>
      <div
        v-for="product in products"
        :key="product.id"
        class="border border-gray-300 p-4 rounded-lg flex flex-col justify-between w-80 min-h-40"
      >
        <div>
          <p class="text-md font-semibold">{{ product.name }}</p>
          <p v-if="product.description" class="mt-1">{{ product.description }}</p>
          <p v-else class="text-gray-500 text-sm italic">No description available.</p>
        </div>
        <div class="flex justify-between items-center">
          <ProductStageBadge v-if="product" :stage="product.stage" />
          <div class="flex gap-2">
            <LoadingSpinner v-if="toggling" />
            <Toggle
              v-else
              v-model="product.active"
              label=""
              variant="success"
              class="mb-0"
              @change="toggleProduct(product)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert.vue';
import ProductStageBadge from '@/components/ProductStageBadge';
import Toggle from '@/components/rbComponents/Toggle';
import ProductServiceV2 from '@/lib/product-service-v2.js';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import parseError from '@/lib/parse-error';

export default {
  components: {
    Alert,
    ProductStageBadge,
    Toggle,
    LoadingSpinner
  },
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      organization: null,
      products: [],
      loading: true,
      toggling: false,
      errorMessage: null
    };
  },
  async mounted() {
    await this.getOrganization();
    await this.getProducts();
  },
  methods: {
    async getProducts() {
      this.loading = true;

      try {
        const result = await ProductServiceV2.listProducts();

        result.data.map(
          (product) => (product.active = this.organization?.enabledProducts?.includes(product.sku) ? true : false)
        );

        this.products = result.data;
        this.loading = false;
      } catch (error) {
        this.errorMessage = parseError(error).message;
        this.loading = false;
      }
    },
    async toggleProduct(product) {
      this.toggling = true;
      product.active = !product.active;

      try {
        // Update the product/organization
        const data = {
          active: product.active,
          organizationId: this.organization.id
        };

        await ProductServiceV2.updateProduct(product.sku, data);

        // Refresh organization for updated active products
        await this.getOrganization();
        await this.getProducts();
        this.toggling = false;
      } catch (error) {
        this.errorMessage = parseError(error).message;
        this.toggling = false;
      }
    },
    async getOrganization() {
      try {
        const id = this.organizationId;

        this.organization = await OrganizationServiceV2.retrieveOrganization(id);
        this.$store.commit('SET_ORGANIZATION', this.organization.id);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    }
  }
};
</script>
