import Axios from '@/axios';

const productUrl = `/product-service/v2/products`;

const listProducts = async (params = {}) => {
  const response = await Axios.get(productUrl, {
    params
  });

  return response.data.data;
};

const updateProduct = async (sku, body) => {
  const response = await Axios.patch(`${productUrl}/${sku}`, body);

  return response.data.data;
};

export default {
  listProducts,
  updateProduct
};
